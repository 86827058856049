export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    key: "index",
    width: 60,
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: "产品编号",
    dataIndex: "number",
    key: "number",
    width: 120,
    sorter: true,
  },
  {
    title: "产品名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "英文名称",
    dataIndex: "english_name",
    key: "english_name",
  },
  {
    title: "包装规格",
    dataIndex: "spec",
    key: "spec",
    width: 80,
  },
  {
    title: "单位",
    dataIndex: "unit",
    key: "unit",
    width: 60,
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    width: 80,
    scopedSlots: { customRender: "action" },
  },
];
